<template>
  <div class="d-block login-with-email">
    <v-alert
      v-if="loginError"
      color="#F9E1E5"
      transition="scroll-y-transition"
      class="d-flex align-center rounded-lg mb-8"
    >
      <div class="login-wrong-credentials-text">
        <v-icon color="#af233a" size="14" class="me-2 ph-fill">$cancel</v-icon>
        {{ $t("login.wrongCredentials") }}
      </div>
    </v-alert>
    <v-form ref="form" @submit.prevent="logIn">
      <div class="mb-4 input-label">E-mail</div>
      <v-text-field
        v-model="email"
        single-line
        outlined
        hide-details
        height="52"
        class="rounded-lg pb-3"
        :placeholder="$t('login.typeEmail')"
        :rules="[rules.validEmail]"
        :disabled="loading"
        autocomplete="username"
      />
      <div class="pt-3 mb-4 input-label">{{ $t("common.password") }}</div>
      <v-text-field
        v-model="password"
        single-line
        outlined
        hide-details
        class="rounded-lg pb-3"
        :placeholder="$t('login.typePassword')"
        :disabled="loading"
        :append-icon="hidePass ? 'mdi-eye-off' : 'mdi-eye'"
        :type="hidePass ? 'password' : 'text'"
        @click:append="hidePass = !hidePass"
        autocomplete="current-password"
      />
      <div class="d-flex justify-end mb-5">
        <v-btn text height="24" class="text-none px-0 me-2 forgot-password">
          {{ $t("login.forgotPassword") }}
        </v-btn>
      </div>
      <div>
        <v-btn
          block
          color="#613FC8B2"
          height="48"
          class="text-none white--text rounded-lg login-submit-button mb-3"
          @click="logIn"
          :loading="loading"
          :disabled="loading"
        >
          <div class="enter-text">{{ $t("login.enter") }}</div>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LoginWithEmail",

  data() {
    return {
      email: "",
      password: "",
      hidePass: true,
      loading: false,
      rules: {
        validEmail: (e) =>
          !!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(e) ||
          this.$t("login.typeValidEmail"),
      },
      loginError: false,
    };
  },

  computed: {
    ...mapGetters(["showOnboardingUser", "nextPage"]),
  },

  methods: {
    ...mapMutations(["setToken", "setTokenReseller"]),

    validateForm() {
      this.formIsValid = this.$refs.form.validate();
      return this.formIsValid;
    },

    async authorizate(userCredential) {
      const idToken = await userCredential.user.getIdToken();
      const url = `${process.env.VUE_APP_API_BASE_URL}/auth/email`;
      let token;
      try {
        token = await this.$axios.get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
      } catch (error) {
        console.error(error);
      }
      if (!token) return;

      this.setToken(token);
      // this.setTokenReseller(tokenReseller); // TODO Reseller login
      localStorage.setItem("show_onboarding", this.showOnboardingUser);
      localStorage.setItem("companyOauth", "email");
      if (this.nextPage) {
        const nextPage = this.nextPage;
        localStorage.removeItem("nextPage");
        this.$router.push({ name: nextPage });
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    async logIn() {
      this.loading = true;
      if (!this.validateForm()) return (this.loading = false);

      const auth = getAuth();

      let userCredential;

      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        this.loginError = false;
      } catch (error) {
        console.error(error);
        // if (error.message.includes("auth/user-not-found")) {
        // }
        this.loginError = true;
      }

      if (!userCredential) return (this.loading = false);

      await this.authorizate(userCredential);

      this.loading = false;
    },
  },
};
</script>

<style>
.login-with-email .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 4px !important;
}

.login-with-email .v-alert--text:before {
  opacity: 1;
}
</style>

<template>
  <v-card tile flat color="transparent" max-width="410" class="mx-auto">
    <div class="d-block justify-center pb-3">
      <LoginWithGoogle />
    </div>

    <div class="d-block justify-center pb-6">
      <LoginWithMicrosoft />
    </div>

    <div class="d-flex align-center justify-center pb-7">
      <v-divider class="or-divider ms-1 me-6" />
      <div class="or-text">{{ $t("common.or") }}</div>
      <v-divider class="or-divider ms-6 me-1" />
    </div>

    <div class="d-block justify-center">
      <LoginWithEmail />
    </div>

    <div class="d-flex justify-center">
      <v-card-text class="text-center pb-0" style="max-width: 400px">
        <div
          class="login-footer-text mb-6"
          v-html="$t('login.new.createAccountTerms')"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import LoginWithEmail from "@/components/new_login/LoginWithEmail.vue";
import LoginWithGoogle from "@/components/new_login/LoginWithGoogle.vue";
import LoginWithMicrosoft from "@/components/new_login/LoginWithMicrosoft.vue";

export default {
  name: "LoginActions",

  components: {
    LoginWithEmail,
    LoginWithGoogle,
    LoginWithMicrosoft,
  },
};
</script>

<style>
.or-divider {
  border: 0.91px solid #8e8e8e !important;
}
.or-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.04px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
</style>

<template>
  <v-btn
    color="#8E8E8E"
    height="50"
    class="text-none rounded-lg"
    style="font-family: Montserrat"
    @click="login"
    block
    outlined
  >
    <div class="d-flex align-center justify-center">
      <div class="d-flex align-center justify-start" style="width: 170px">
        <div>
          <v-img
            :src="logo_google"
            :width="20"
            :height="20"
            contain
            class="login-button-logo me-3"
          />
        </div>
        <div class="login-action-text">{{ $t("login.loginGoogle") }}</div>
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "LoginWithGoogle",

  data() {
    return {
      logo_google: require("@/assets/images/logo_google.png"),
    };
  },

  methods: {
    login() {
      window.location.href = process.env.VUE_APP_API_BASE_URL + "/auth";
    },
  },
};
</script>
<style>
.login-text-spotlight {
  color: var(--v-accent-base);
  background-color: rgba(78, 58, 100, 0.9);
  padding: 0px;
  opacity: 0.8;
}

.login-slogan-text {
  font-size: 38px;
}

.login-button-logo {
  mix-blend-mode: multiply;
}
</style>

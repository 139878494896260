<template>
  <v-overlay absolute :value="show" opacity="0.6">
    <v-card flat color="grey lighten-3" class="pa-5" max-width="440px">
      <v-card-title
        class="mb-0 text-h6 title font-weight-medium black--text text-break"
      >
        {{ $t("scopesDialog.title") }}
      </v-card-title>
      <v-card-subtitle class="ma-0 py-2 black--text">
        {{ $t("scopesDialog.permissions") }}
      </v-card-subtitle>
      <v-card-subtitle class="ma-0 py-2 black--text">
        {{ $t("scopesDialog.privacy") }}
      </v-card-subtitle>
      <v-card-subtitle
        class="ma-0 py-2 black--text"
        v-html="$t('scopesDialog.faq')"
      />
      <v-card-subtitle class="ma-0 mb-5 pt-0 black--text">
        {{ $t("scopesDialog.acceptAll") }}
      </v-card-subtitle>

      <v-btn
        :color="'primary'"
        height="48"
        width="100%"
        dark
        class="font-weight-bold text-none text-body-1 white--text"
        :href="`https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${suiteAppId}`"
        target="_blank"
        @click="$emit('close')"
      >
        {{
          $vuetify.breakpoint.mobile
            ? $t("scopesDialog.goToPanel")
            : $t("scopesDialog.goToAdminConsole")
        }}
      </v-btn>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "ScopesDialog",
  props: {
    show: Boolean,
  },

  data() {
    return {};
  },
  computed: {
    suiteAppId() {
      return process.env.VUE_APP_MARKETPLACE_APP_ID;
    },
  },
};
</script>

<style></style>

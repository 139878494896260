<template>
  <v-btn
    color="#8E8E8E"
    height="50"
    class="text-none rounded-lg"
    style="font-family: Montserrat"
    @click="msAuth"
    block
    outlined
  >
    <div class="d-flex align-center justify-center">
      <div class="d-flex align-center justify-start" style="width: 170px">
        <div>
          <v-img
            :src="logo_microsoft"
            :width="20"
            :height="20"
            contain
            class="login-button-logo me-3"
          />
        </div>
        <div class="login-action-text">{{ $t("login.loginMicrosoft") }}</div>
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "LoginWithMicrosoft",

  data() {
    return {
      logo_microsoft: require("@/assets/images/logo_microsoft.png"),
    };
  },

  methods: {
    msAuth() {
      window.location.href = `${process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL}/login`; // TODO redirect to microsoft login
    },
  },
};
</script>
<style scoped>
.login-button-logo {
  mix-blend-mode: multiply;
}
</style>
